import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Content from "./components/Content/Content";
import Header from "./components/Header/Header";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
  },
  content: {
    textAlign: "center",
  },
});

function App() {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header></Header>

      <Content>
        <p className={classes.content}>
          ABW Vessel Management Limited is a Company registered in the Bahamas.
          <br />
          <br />
          The company undertakes Ships &amp; Mobile Offshore Drilling Unit
          <br />
          <br />
          Projects and Operations Management. The company is based out of
          <br />
          <br />
          Freeport, Grand Bahama, Bahamas.
        </p>
      </Content>
    </div>
  );
}

export default App;
