import {
  Button,
  Card,
  CardActions,
  CardContent,
  Icon,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  root: {
    marginTop: "2%",
    marginBottom: "2%",
    minWidth: 275,
    width: "90%",
  },
  cardActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "5% 5%",
  },
  address: {
    marginTop: "5%",
    textAlign: "left",
  },
});

function Content(props: any) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2" component="div">
          {props.children}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          startIcon={<Icon>mail</Icon>}
          href="mailto:info@abwvm.com"
          target="_blank"
        >
          CONTACT US
        </Button>
        <Typography className={classes.address} variant="body2" component="div">
          <Link href="tel:+19566162035">
            <Icon>phone</Icon> <span>+1 956 616 2035</span>
          </Link>
        </Typography>
        <Typography
          className={classes.address}
          variant="body2"
          component="address"
        >
          ABW Vessel Management,<br />
          5 Queens Highway,<br />
          Freeport,<br />
          Grand Bahama,<br />
          Bahamas.
        </Typography>
      </CardActions>
    </Card>
  );
}

export default Content;
