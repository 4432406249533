import React from "react";
import {
  Container,
  Typography,
  Paper,
  Avatar,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import BannerImage from "../../assets/banner.jpg";
import LogoImage from "../../assets/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgr: {
      height: "470px",
      overflow: "hidden",
      position: "relative",
      backgroundImage: `url(${BannerImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "100%",
    },
    logo: {
      width: "250px",
      height: "250px",
      textAlign: "center",
      position: "absolute",
      left: "15%",
      [theme.breakpoints.up("xs")]: {
        left: "0%",
        top: "15%",
      },
    },
    companyName: {
      maxWidth: "330px",
      color: "#FFFFFF",
      position: "absolute",
      top: "75%",
      left: "15%",
      [theme.breakpoints.up(510)]: {
        maxWidth: "500px",
      },
    },
    companyNameBig: {
      fontSize: 34,
    },
  })
);

function Header() {
  const classes = useStyles();

  return (
    <Container className={classes.bgr} disableGutters maxWidth={false}>
      <Avatar
        className={classes.logo}
        alt="ABW Vessel Management Ltd."
        src={LogoImage}
      />
      <Typography className={classes.companyName} variant="h6" component="h1">
        <span className={classes.companyNameBig}>ABW</span> Vessel Management
        Ltd.
      </Typography>
    </Container>
  );
}

export default Header;
